import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Inject, Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, TemplateRef, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BaseService } from 'app/@core/services/base.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormGroup, FormControl } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import * as screenfull from 'screenfull';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'app/@core/services/utilities.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { StorageService } from "app/@core/services/storage.service";
import { ActivatedRoute} from '@angular/router';
import { Confirmable } from 'app/shared/decorator/confirmable.decorator';
import { DOCUMENT } from '@angular/common'
declare var $: any;

@Component({
  selector: 'cs-table',
  styleUrls: ['cs-datatable.component.scss'],
  templateUrl: 'cs-datatable.component.html',
})
export class CSDataTableComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('fileInput') fileInput: any;
  @ViewChildren('columnCells') columnCells: QueryList<ElementRef>;

  private resizeObserver: ResizeObserver;
  @Input() headerTitle = '';
  @Input() tableSettingKey = '';
  @Input() addButtonTitle = '';
  @Input() APIURL : any = '';
  @Input() listDataKey = ''
  @Input() cellTemplate: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() gridCount;
  @Input() tableData = [];
  @Input() columns = [];
  @Input() addBtn;
  @Input() placeholderText;
  @Input() menuColumns;
  @Input() defaultPayLoad = {};
  @Input() isShowDateFilters = false;
  @Input() storeTableData = '';
  @Input() isShowExport = false;
  @Input() importURL = '';
  @Input() isEnableMultiSearch = false;
  @Input() importInstructions: any;
  @Input() isSearch: boolean = false
  @Input() isColBtn: boolean = true
  @Input() isFilterBtn: boolean = true
  @Input() isImportBtn: boolean = true
  selectedOptionValue: string = 'Assets';
  selectedOptionValue1: string = 'Running Dwell';
  selectedAssets: any = [];
  selectedLocations: any = [];
  @Input('timeDelay') timeDelay: string = "";
  @Output() filterChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter();
  @Output() changedEvent = new EventEmitter();
  @Output() addFunClicked = new EventEmitter<void>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>
  dataSource: MatTableDataSource<[]>;
  public files: NgxFileDropEntry[] = [];
  uploadedDocuments: any = [];
  IsPageLoaded: boolean = true;
  columnAssetType:any

  isShowFilterRow = false;
  filtersArray = [];
  sortingData: any;
  isLoading = true;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  menuModal: any;
  maxMin = false;
  isFullScreen: any = false;
  isLastRef = false;
  lastRefresh = new Date();
  setTimeINterval = undefined;
  isDateTogggleEnable = true;
  arrivalTimeEnable = true;
  reportDateTime = 'all';
  arrivalTime = 'Arrival Date';
  showTooltip = true;
  arr: any = [];
  isFilter: boolean = false;
  isReset: string = '';
  assetsArray :any = [];
  locationsArray :any = [];
  isView:boolean=false;
  searchOptions = ['Assets', 'Devices', 'All']

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: false,
  };

  displayedColumns = [];
  filterColumns = [];
  searchInput = '';

  columnObj: any = {
    userId: localStorage.getItem('companyUserId'),
    json: [],
    type: 'Custom',
    page: '',
    companyId: this.storageService.getCompanyID()
  }
  presetName:any = null;

  importValidationsErrors = [];

  todayDate = new Date();
  sevenDaysAgo = new Date(this.todayDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  thirtyDaysAgo = new Date(this.todayDate.getTime() - (30 * 24 * 60 * 60 * 1000));
  daterange = new FormGroup({
    start: new FormControl(this.sevenDaysAgo),
    end: new FormControl(this.todayDate),
  });

  get startDateControl(): FormControl {
    return this.daterange.get('start') as FormControl
  }

  get endDateControl(): FormControl {
    return this.daterange.get('end') as FormControl
  }

  CompanyArr: any = [];
  selectedCompany;
  showCompany = false;
  public searchAssetName: FormControl = new FormControl();
  public searchLocationName: FormControl = new FormControl();

  constructor(
    private baseService: BaseService,
    private ref: ChangeDetectorRef,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    public utlititiesService: UtilitiesService,
    private modalService: BsModalService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private renderer: Renderer2, 
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        // Handle the resized column element
        // Perform actions based on the width change
        if (entry.contentRect.width < 40) {
          this.showTooltip = true;
          break;
        }
        else {
          this.showTooltip = false;
        }
      }
    });

  }

  ngOnInit() {
     
    this.IsPageLoaded = true;
    let parent = this.route?.parent?.snapshot?.data?.expectedRole;
    // let parent = this.route?.parent?.snapshot?.data?.expectedRole;
    // let parent = this.route.snapshot.routeConfig.data.expectedRole;
    let url = this.route.snapshot.routeConfig.path;
    let backUrl = parent + '/' + url;
    if(parent){
      localStorage.setItem('backUrl', backUrl)
    } else if (parent === undefined && url === "tracked-assets") {
      localStorage.setItem('backUrl', 'tracked-assets')
    } else if (parent === undefined && url === "track-history") {
      localStorage.setItem('backUrl', 'track-history')
    }
    localStorage.setItem('tableSettingKey', this.tableSettingKey + "-filterJSON");
    if (this.tableSettingKey === 'DwellTime'){
      this.daterange.get('start').setValue(this.thirtyDaysAgo);
    }
    this.dataSource = new MatTableDataSource<[]>([]);
    let filteredData: any = localStorage.getItem(this.tableSettingKey + "-filterJSON");
    let responsepreset:any = localStorage.getItem("responsePresetName");
    let isViewBool:any = localStorage.getItem("isViewBool");
    if (filteredData && filteredData != "null") {
      if(responsepreset && responsepreset!=="null"){
        let parseData = JSON.parse(responsepreset);
        var presetName=parseData
      }
      if(isViewBool && isViewBool!=="null"){
        let parseData = JSON.parse(isViewBool);
        var isView=parseData
      }
      let parseData = JSON.parse(filteredData);
      let currentPageNumber = parseData?.pageNumber;
      let isSortData = parseData?.sort;
      // let presetName = parseData?.presetName;
      let isFilterData = parseData?.filters
      let isRefresh = parseData?.isRefresh;
      let isSearchInput: string[] = parseData?.assetIdList;
      this.isShowFilterRow = parseData?.isShowFilter;
      if (isFilterData?.length != 0) {
        isFilterData?.map(filter => {
          if (filter?.filterValue) {
            this.filterPayload.push(filter);
          }
        });
      }
       
      // if (Object?.keys(isSortData).length != 0) {
      if (isSortData && Object.keys(isSortData).length !== 0) {
        let sortingData = {
          active: parseData?.sort?.field,
          direction: parseData?.sort?.sort ? 'asc' : 'desc'
        }
        this.sortingData = sortingData
      }
      if (parseData?.pageSize) {
        this.pageSize = parseData.pageSize
      }
      if(presetName){
        this.presetName = presetName
      }
      if(isView){
        this.isView = isView
      }
      if (currentPageNumber) {
        this.currentPage = currentPageNumber - 1;
      }
      if (isRefresh) {
        this.isLastRef = isRefresh;
        this.onChangeLastRefresh();
      }
      if (isSearchInput) {
        isSearchInput.forEach(item => {
          let val = " " + item;
          this.searchInput += val;
        })
      }
    }
    const assetPoolFilter = JSON.parse(localStorage.getItem('assetPoolFilter'))
    if (assetPoolFilter && assetPoolFilter != null) {
      this.filterPayload = []
      this.searchInput = "";
      this.IsPageLoaded = false;
      assetPoolFilter.map(filter => this.filterPayload.push(filter))
    }

    localStorage.removeItem('assetPoolFilter')
    if(this.APIURL !== "deviceeventhistory" && this.APIURL !== "eventhistory"){
      this.loadData();
    }
    if (this.filterPayload.length != 0) {
      this.isShowFilterRow = true;
    }
    this.getAllCompanies();
    if (this.APIURL === 'trackedassets' || this.APIURL === 'trackhistory' || this.APIURL === 'assetpool/assetpools' || this.APIURL === 'dwelltimes') {
      this.showCompany = true;
    } else {
      this.showCompany = false;
    }
    if (this.tableSettingKey === 'DwellTime'){
      this.assetsArray = [];
      this.baseService.showLoader();
      this.baseService.getRequest(`assetdevice/assetsdropdown?CompanyId=${this.storageService.getCompanyID()}`)
      .subscribe((response) => {
          this.assetsArray = response;
          this.baseService.hideLoader();
        }, (error) => {
          this.toastr.error(`Some error on your form.`, 'Opps!');
          console.log(error);
          this.baseService.hideLoader();
        });


        this.locationsArray = [];
        this.baseService.showLoader();
        this.baseService.getRequest(`location/locationdropdown?companyId=${this.storageService.getCompanyID()}`)
        .subscribe((response) => {
            this.locationsArray = response;
            this.baseService.hideLoader();
          }, (error) => {
            this.toastr.error(`Some error on your form.`, 'Opps!');
            console.log(error);
            this.baseService.hideLoader();
          });
    }
  }

  onSelectOption() {
    this.currentPage = 0;
    this.loadData();
  }

  showTooltipValue(row, col) {
    if (col.field == "battery") {
      return row.battery ? row.battery + '%' : '0 %';
    }
    else if (col.field == "assetName") {
      return row.assetName ?? '';
    } else if (col.field == "localTimeZone") {
      return row.localTimeZone ?? '';
    } else if (col.field == "dwellTimeTotal") {
      return row.dwellTime ?? '';
    } else if (col.field == "idleTime") {
      return row.idleTime ?? '';
    } else if (col.field == "longitude") {
      return row.longitude ?? '';
    } else if (col.field == "excursionTimeTotal") {
      return row.excursionTime ?? '';
    } else if (col.field == "lastReportedTime") {
      return row.lastReportedTime ?? '';
    } else if (col.field === 'assetType') {
      return row.assetType ?? '';
    } else if (col.field === 'locationName') {
      return row.locationName ?? '';
    } else if (col.field === 'actualCount') {
      return row.actualCount ?? '';
    } else if (col.field === 'heighestDwell') {
      return row.heighestDwell ?? '';
    } else if (col.field === 'heighestExcursion') {
      return row.heighestExcursion ?? '';
    } else if (col.field === 'deviation') {
      return row.deviation ?? '';
    } else if (col.field === 'createdDate') {
      return row.createdDate ?? '';
    } else if (col.field === 'incomplete') {
      return row.incomplete ? '' : '';
    }
    else if (col.field == "firstMove") {
      return row.firstMove ? 'Yes' : 'No'
    }
    else if (col.field === 'isEnabled') {
      return row.isEnabled ? 'Yes' : 'No';
    }
    else if (col.field === 'isGeofenceEnabled') {
      return row.isGeofenceEnabled ? 'Yes' : 'No';
    }
    else if (col.field === 'isDomicile') {
      return row.isDomicile ? 'Yes' : 'No';
    }

    else {
      return ''

    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }

  addDecimal(dewilTime: string) {
    let dewilTimeWithDecimal: string = '';
    if (dewilTime.indexOf('.') != -1) {
      return dewilTimeWithDecimal = dewilTime + '.0'
    }

    return dewilTime;
  }

  loadData() {
     
    this.isLoading = true;
    this.baseService.showLoader();
     
    const data: any = this.getPayLoad();
    let postData = {
      ...data,
    }
    if (this.isDateTogggleEnable && this.isShowDateFilters) {
      postData['dateRange'] = {
        start: moment(this.daterange.value.start).format('YYYY-MM-DD'),
        end: moment(this.daterange.value.end).format('YYYY-MM-DD')
      }
    }
    this.baseService.postRequest(this.APIURL, postData).subscribe(response => {
      
      const responseData = response[this.listDataKey] ? response[this.listDataKey] : response;

      if (response.isTrackAsset === true) {
        var toNumber = response.trackedAssetResponse.map(obj => ({
          ...obj,
          longitude: obj.longitude === null || obj.longitude === "" ? 0 : parseFloat(obj.longitude),
          latitude: obj.latitude === null || obj.latitude === "" ? 0 : parseFloat(obj.latitude),
        }));

        var addDecimal = toNumber?.map(obj => ({
          ...obj,
          dwellTime: obj.dwellTime != null ? obj.dwellTime.toFixed(1) : "",
          battery: obj.battery != null ? obj.battery.toFixed(1) : "",
          excursionTime: obj.excursionTime != null ? obj.excursionTime.toFixed(1) : "",
          idleTime: obj.idleTime != null ? obj.idleTime.toFixed(1) : "",
          longitude: obj.longitude != "" ? obj.longitude.toFixed(4) : "",
          latitude: obj.latitude != "" ? obj.latitude.toFixed(4) : "",
          moveFrequency: obj.moveFrequency != null ? obj.moveFrequency.toFixed(1) : "",
          distanceFromDomicileInMeters: obj.distanceFromDomicileInMeters != null ? obj.distanceFromDomicileInMeters.toFixed(1) : "",
        }));
        this.arr = addDecimal
      }
      else if (response.isTrackAsset == false) {
        var toNumber = response.trackHistoryResponse.map(obj => ({
          ...obj,
          longitude: obj.longitude === null || obj.longitude === "" ? 0 : parseFloat(obj.longitude),
          latitude: obj.latitude === null || obj.latitude === "" ? 0 : parseFloat(obj.latitude),
        }));

        var addDecimal = toNumber?.map(obj => ({
          ...obj,
          dwellTimeTotal: obj.dwellTimeTotal != null ? obj.dwellTimeTotal.toFixed(1) : "",
          excursionTimeTotal: obj.excursionTimeTotal != null ? obj.excursionTimeTotal.toFixed(1) : "",
          idleTime: obj.idleTime != null ? obj.idleTime.toFixed(1) : "",
          longitude: obj.longitude != "" ? obj.longitude.toFixed(4) : "",
          latitude: obj.latitude != "" ? obj.latitude.toFixed(4) : "",
          moveFrequency: obj.moveFrequency != null ? obj.moveFrequency.toFixed(1) : "",
          distanceFromDomicileInMeters: obj.distanceFromDomicileInMeters != null ? obj.distanceFromDomicileInMeters.toFixed(1) : "",
        }));
        this.arr = addDecimal
      }
      else if (response.isTrackAsset == 3) {
        var addDecimal = response.eventHistory.map(obj => ({
          ...obj,
          dwellTime: obj.dwellTime != null ? obj.dwellTime.toFixed(1) : "",
          excursionTime: obj.excursionTime != null ? obj.excursionTime.toFixed(1) : "",
          idleTime: obj.idleTime != null ? obj.idleTime.toFixed(1) : "",
          moveFrequency: obj.moveFrequency != null ? obj.moveFrequency.toFixed(1) : "",
          distanceFromDomicile: obj.distanceFromDomicile != null ? obj.distanceFromDomicile.toFixed(1) : "",
          distanceFromLastEvent: obj.distanceFromLastEvent != null ? obj.distanceFromLastEvent.toFixed(1) : "",
        }));
        this.arr = addDecimal
      }
      else if (response.isTrackAsset == 4) { 
        var addDecimal = response.assetResponse.map(obj => ({
          ...obj,
          battery: obj.battery != null ? obj.battery.toFixed(1) : ""
        }));
        this.arr = addDecimal
      }
      else {
        this.arr = responseData
      }


      this.dataSource = new MatTableDataSource(this.arr);
      this.baseService.setData(this.arr);
      if (this.storeTableData) {
        sessionStorage.removeItem(this.storeTableData);
        sessionStorage.setItem(this.storeTableData, JSON.stringify(this.arr));
      }
      this.baseService.gridCount.next(response.gridCount);
      if (this.dataSource && this.dataSource?.data?.length) {
        setTimeout(() => {
          if (this.paginator) {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = response.gridCount;
          }
        });
      }
      this.isLoading = false;
      this.baseService.hideLoader();
    }, error => {
      console.log(error);
      this.isLoading = false;
      this.baseService.hideLoader();
      this.dataSource.data = [];
    });
  }

  addNewFunc() {
    this.addFunClicked.emit();
  }

  getAllCompanies() {
    this.CompanyArr = [];
    this.baseService.showLoader();
    this.baseService.getRequest('company/company')
      .subscribe((response) => {
        this.CompanyArr = response;
        const id = this.storageService.getCompanyID();
        this.selectedCompany = this.CompanyArr.find(item => item.companyId === id);
        this.baseService.hideLoader();
      }, (error) => {
        this.toastr.error(`Some error on your form.`, 'Opps!');
        console.log(error);
        this.baseService.hideLoader();
      })
  }

  searchParams: any = {};
  filterPayload: any = [];
  getPayLoad() {
     
    // let filteredData: any = localStorage.getItem(this.tableSettingKey + "-filterJSON");
    // if(filteredData && filteredData!=="null"){
    //   let parseData = JSON.parse(filteredData);
    //   this.presetName=parseData.presetName
    // }
    let responsePreset:any =localStorage.getItem("responsePresetName");
    if(responsePreset && responsePreset!=="null"){
      let parseData = JSON.parse(responsePreset);
      this.presetName=parseData
    }
    let isViewBool:any =localStorage.getItem("isViewBool");
    if(isViewBool && isViewBool!=="null"){
      let parseData = JSON.parse(isViewBool);
      this.isView=parseData
    }
     
    let data1: any = this.getSort();
    let postData = {
      ...this.defaultPayLoad,
      CompanyId: this.storageService.getCompanyID(),
      pageNumber: this.currentPage + 1,
      pageSize: this.pageSize || 10,
      sort: {
        ...data1.sorting
      },
      filters: [...this.filterPayload],
      isPageLoaded: this.IsPageLoaded,
      userId: localStorage.getItem('companyUserId') == "" ? null : localStorage.getItem('companyUserId'),
      isShowFilter: this.isShowFilterRow,
      isRefresh: this.isLastRef,
      presetName: this.presetName,
      isView: this.isView
    };
    if (this.isEnableMultiSearch) {
      postData['assetIdList'] = this.searchInput?.trim().length ? this.searchInput?.trim().split(/\s+/) : [];
      postData['locationNameList'] = this.searchInput?.trim().length ? this.searchInput?.trim().split(/\s+/) : [];
    }
    if(this.tableSettingKey === 'TrackedAssets') {
      postData['DataCondition'] = this.selectedOptionValue
    }
    if(this.tableSettingKey === 'TrackHistory') {
      postData['DataCondition'] = this.selectedOptionValue
    }
    if(this.tableSettingKey === 'DwellTime') {
      postData['dateCondition'] = this.arrivalTime
      postData['dataCondition'] = this.selectedOptionValue1
      postData['assetIdList'] = this.selectedAssets
      postData['locationNameList'] = this.selectedLocations
    }
    const updatedPostData = {
      ...postData,
      filterJson: JSON.stringify(postData)
    }
    this.IsPageLoaded = false
    return updatedPostData
  }

  ngOnChanges(changes: SimpleChanges) { 
    const assetID = changes.defaultPayLoad?.currentValue?.assetId;
    if (assetID || assetID == 0) {
      this.loadData();
    }
    // if (changes.defaultPayLoad?.currentValue?.assetId) {
    //   this.loadData();
    // }
    if (changes.columns && changes.columns.currentValue.length) {
      this.setDisplayedColumns();
    }
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      setTimeout(() => {
        this.adjustColumnsWidth();
        this.columnCells.changes.subscribe(() => {
          this.observeColumnWidthChanges();
        });
      });

      setTimeout(() => {
        $(".resizeColumn").resizable({
          maxHeight: 30,
          maxMin: true,
          minWidth: 80, 
          maxWidth: 1040,
        });
      });
    });

    const params = this.route.snapshot.params;
    if (JSON.stringify(this.route.snapshot.params).length) {
      for (const key in params) {
        const value = params[key];
        const data = {
          filterKey: key,
          filterValue: value
        };
        this.filtersArray.push(data);
      }

    }
    this.daterange.valueChanges.pipe(
      filter(values => !!(values.start && values.end && this.isDateTogggleEnable))
    ).subscribe(() => {
      this.loadData();
    })
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator = this.paginator
  }

  private observeColumnWidthChanges() {
    
    this.resizeObserver.disconnect();
    this.columnCells.forEach(cell => {
      this.resizeObserver.observe(cell.nativeElement);
    });
  }

  setDisplayedColumns() {
    setTimeout(() => {
      if (!this.menuColumns || !this.menuColumns.length) {
        this.menuColumns = Array.from([...this.columns.slice()]);
      }
      this.menuColumns = JSON.stringify(this.menuColumns)
    });
    this.displayedColumns = this.columns.filter(column => column.isShowColumn).map(column => column.field);
    this.filterColumns = this.columns.filter(column => column.isShowColumn).map(column => column.field + '-filter');
    this.ref.detectChanges();
  }

  showHideFilter() {
     
    let filteredData: any = localStorage.getItem(this.tableSettingKey + "-filterJSON");
    if (filteredData && filteredData != "null") {
      let parseData = JSON.parse(filteredData);
      if (parseData?.pageSize) {
        this.pageSize = parseData.pageSize
      }
    }
    this.filterPayload = []
    this.sortingData = {}
    this.currentPage = 0
    this.IsPageLoaded = false;
    this.isShowFilterRow = !this.isShowFilterRow;
    this.loadData();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  };


  applyColumnChanged(columns) {
    this.columns = [...columns];
    this.baseService.showLoader();
    this.isShowFilterRow = false;
    setTimeout(() => {
      this.baseService.hideLoader();
      this.closeModal();
    }, 1000);
    this.adjustColumnsWidth();
    this.displayedColumns = this.columns.filter(column => column.isShowColumn).map(column => column.field);
    this.filterColumns = this.columns.filter(column => column.isShowColumn).map(column => column.field + '-filter');
  }

  saveColumnsMenu(menu) {
    this.columnObj.page = this.tableSettingKey;
    this.columns.map(it => delete it.cellTemplate);
    this.columnObj.json = menu;
    this.isLoading = true;
    this.baseService.showLoader();
    
    this.baseService.postTableSettings(this.tableSettingKey, this.columnObj)
      .subscribe(() => {
        this.baseService.hideLoader();
        this.baseService.reloadCoulmn.next({ 'hard-reload': true });
      }, (error) => {
        console.log(error);
      });
  }

  moveItemInArray(array: any[], fromIndex: number, toIndex: number) {
    const item = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, item);
    return array;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.columns[event.currentIndex]['isDragAble']) {
      const columnArray = Array.from([...this.columns.slice()]);
      const newArray = this.moveItemInArray(columnArray, event.previousIndex, event.currentIndex);
      this.displayedColumns = newArray.filter(column => column.isShowColumn).map(column => column.field);
      this.filterColumns = newArray.filter(column => column.isShowColumn).map(column => column.field + '-filter');
      this.saveColumnsMenu(newArray);
    }
  }

  announceSortChange(sortState: Sort) {

    this.sortingData = sortState;
    this.sortChanged.next(sortState);
    this.changedFilters();
  }

  filteredData = {};
  payload: any[] = [];
  /**
   * @param filterValue: A value that you want to search data about
   */
  setFilterValue(source: { filterKey: string, filterValue: any }) {


    if (source.filterValue) {
      if (!this.filterPayload.length) {
        this.filterPayload.push(source);
      }

      else {
        const index = this.filterPayload.findIndex(obj => obj.filterKey === source.filterKey);

        if (index !== -1) {
          this.filterPayload[index].filterValue = source.filterValue;
        } else {
          this.filterPayload.push(source);
        }


      }
    } else {

      let indexToDelete = this.filterPayload.findIndex(data => data.filterKey === source.filterKey);
      if (indexToDelete !== -1) {
        this.filterPayload.splice(indexToDelete, 1);
      }
    }
  }

  applyFilter(event) {
    if (event.filterValue.toString().length) {
      this.setFilterValue(event);
    } else {
      this.searchParams = {}
      this.setFilterValue(event);

    }
    this.currentPage = 0;
    this.filterChanged.emit(this.filtersArray);
    setTimeout(() => {
      this.changedFilters();
    }, 1000);
  }


  changedFilters() {
    this.loadData()
  }

  getSort() {

    if (!this.sortingData || !this.sortingData.direction?.length) {
      return {
        sorting: {}
      }
    };
    const data = {
      sorting: {
        field: this.sortingData.active,
        sort: this.sortingData.direction == 'asc',
      }
    };
    return data
  }

  export() {
    this.baseService.showLoader();
    const data: any = this.getPayLoad();

    let postData = {
      ...data,
      pageSize: this.paginator.length
    };
    if (this.isDateTogggleEnable && this.isShowDateFilters) {
      postData['dateRange'] = {
        start: moment(this.daterange.value.start).format('YYYY-MM-DD'),
        end: moment(this.daterange.value.end).format('YYYY-MM-DD')
      }
    }
    if (this.dataSource.data.length) {
      this.baseService.exportExcel(this.APIURL + '/export', postData)
        .subscribe((response: HttpResponse<Blob>) => {
          var formattedDate = moment(new Date()).format('YYYY-MM-DD');
          const fileName = 'Export-' + this.tableSettingKey + '-' + formattedDate + '.xlsx';
          saveAs(response, fileName);
          this.baseService.hideLoader();
        }, error => {
          this.toastr.error(`Something went wrong`, 'Opps!');
          console.log(error);
          this.baseService.hideLoader();
        });
    } else {
      this.toastr.error(`As no Records are found. File can't Exported`);
      this.baseService.hideLoader();
    }
  }

  clearDates() {
    this.startDateControl.setValue(null);
    this.endDateControl.setValue(null);
  }

  onReload() {
    if (this.filterPayload.length) {
      this.isShowFilterRow = true;
    }

    this.baseService.showLoader();
    setTimeout(() => {
      this.closeModal();
      this.loadData();
      this.adjustColumnsWidth();
    }, 1000);
  }

  refresh() {
    this.lastRefresh = new Date();
    this.loadData();
  }

  onChangeLastRefresh() {
    const validPaths = ['eztrack-settings', 'access-management'];
    const refershTime = validPaths.some(path => window.location.pathname.includes(path))
      ? environment.AUTO_REFRESH_TIME_OUT_ezSettings
      : environment.AUTO_REFRESH_TIME_OUT;
    this.lastRefresh = new Date();
    if (this.isLastRef) {
      this.loadData();
      this.isLastRef = true;
      this.setTimeINterval = setInterval(() => {
        this.lastRefresh = new Date();
        this.loadData();
      }, refershTime);
    } else {
      clearInterval(this.setTimeINterval);
      this.isLastRef = false;
      this.loadData();
    }
  }

  fullScreenTable(el: HTMLElement) {
    this.isFullScreen = !this.isFullScreen;
    if (screenfull.isEnabled) {
      if (this.isFullScreen) {
        screenfull.request(el);
      } else {
        el.classList.add('fullscreen-exit');
        screenfull.exit();
      }
    }
  }

  @Confirmable({
    title: 'Reset to default table view?',
    text: 'Reset to default table view will reset the filters and sorting',
    confirmButtonColor: '#40c057',
    confirmButtonText: 'Reset',
    denyButtonText: 'Cancel',
    customClass: {
      'actions': "custom-btn-position"
    }
  })
  ResetToDefault() {
     
    this.IsPageLoaded = false;
    this.sort.active = null;
    localStorage.removeItem(this.tableSettingKey + "-filterJSON");
    this.filterPayload = []
    this.sortingData = {}
    this.currentPage = 0
    this.pageSize = 10
    this.sort.direction = '';
    this.sort.sortChange.emit({ active: null, direction: '' });
    this.startDateControl.setValue(this.sevenDaysAgo);
    this.endDateControl.setValue(this.todayDate);
    this.isShowFilterRow = false;
    this.loadData();
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
  }

  onDateToggle() {
    this.loadData();
  }

  openModal(addAssetType: TemplateRef<any>) {
     
    this.columnAssetType=addAssetType
    this.utlititiesService.modalConfig.class = "modal-dialog-centered";
    this.menuModal = this.modalService.show(
      addAssetType,
      this.utlititiesService.modalConfig
    );
  }
  closeModal() {
    if (this.menuModal) {
      this.menuModal.hide();
       
      // this.openModal(this.columnAssetType)
    }
  }
  closeModalButton() {
    if (this.menuModal) {
      this.menuModal.hide();
      
    }
  }

  adjustColumnsWidth() {
    
    const table: any = document?.querySelector('.mitable');
    const resizeableColumns = table?.querySelectorAll('.resizeColumn');
    const tableWidth = table.offsetWidth;
    let columnWidth = tableWidth / resizeableColumns.length;
    columnWidth = columnWidth < 100 ? 100 : columnWidth
    resizeableColumns.forEach(column => {
      column.style.width = columnWidth + 'px';
    });
  }

  openFile() {
    this.importValidationsErrors = [];
    this.fileInput.nativeElement.click();
  }
    userId:any = null;
  public onFileSelected(files: NgxFileDropEntry[]) {
    this.files = files;
    this.importValidationsErrors = [];
    const acceptedExtensions = ['.csv'];
    if (files.length > 1) {
      this.toastr.error(`Please upload a single file`);
      return;
    }

    const csvFiles = Array.from(files).filter((file: any) => {
      const fileName = file.fileEntry.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
      return !!acceptedExtensions.includes(fileExtension)
    });

    if (!csvFiles.length) {
      this.toastr.error(`Please upload a valid csv file`);
      return;
    }
    this.baseService.showLoader();
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const formData = new FormData()
          formData.append('file', file);
          this.userId = localStorage.getItem('companyUserId');  
          const companyId = this.storageService.getCompanyID();
          if (this.userId !== "" && this.userId !== null) {
            this.userId = localStorage.getItem('companyUserId');  
          } 
          else{
            this.userId=0
          }
          this.baseService
            .importExcel(this.importURL + `?companyId=${companyId}&UserId=${this.userId}`, formData)
            .subscribe(response => {
              this.toastr.success(response.message || 'Data is imported successfully');
              this.baseService.hideLoader();
              if (response?.validations && response?.validations?.length) {
                this.importValidationsErrors = response.validations || [];
              }
              this.loadData();
            }, ({ error }) => {
              this.toastr.error(error?.message || 'Something went wrong');
              if (error?.statusCode == 400) {
                this.importValidationsErrors = error.validations || [];
              }
              this.baseService.hideLoader();
            });
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        this.toastr.error(`Please upload a valid file`);
      }
    }
  }

  uploadAvatar(file) {
    if (this.uploadedDocuments.length < 5) {
      this.uploadedDocuments.push({
        fileSize: file.size,
        fileName: file.name.replace(/ /g, '_'),
      });
    } else {
    }

  }
  deleteDoc(index) {
    this.uploadedDocuments.splice(index, 1);
  }

  public fileOver(event) {
    this.importValidationsErrors = [];
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  importModal;
  openImportModal(template: TemplateRef<any>) {
    this.importValidationsErrors = [];
    this.utlititiesService.modalConfig.class = "modal-xl modal-dialog-centered";
    this.importModal = this.modalService.show(
      template,
      this.utlititiesService.modalConfig
    );
  }

  closeImportModal() {
    if (this.importModal) {
      this.importModal.hide();
    }
  }

  multiSearch() {
    this.currentPage = 0;
    this.loadData();
  }

  clearSearch() {
    this.searchInput = '';
    this.loadData();
  }

  showTooltipCondition() {
    return this.showTooltip;
  }

  getBackgroundColor(columnHeaderRef, column) {
    const columnHeader = columnHeaderRef._elementRef.nativeElement;
    const childElement = columnHeader.querySelector('.mat-sort-header-container');
    if (column === 'alarm') {
      this.renderer.setStyle(childElement, 'background-color', '#ff8182');
    }
    else if (column === 'reeferinfo') {
      this.renderer.setStyle(childElement, 'background-color', '#9aacd2');
    }
    else if (column === 'generator') {
      this.renderer.setStyle(childElement, 'background-color', '#d9d9d9');
    }
    else if (column === 'doorinfo') {
      this.renderer.setStyle(childElement, 'background-color', '#afeafe');
    }
    else if (column === 'tireinfo') {
      this.renderer.setStyle(childElement, 'background-color', '#cc99fe');
    }
    else if (column === 'liftgate') {
      this.renderer.setStyle(childElement, 'background-color', '#f8cbac');
    }
    else if (column === 'liftinfo') {
      this.renderer.setStyle(childElement, 'background-color', '#FFFF00');
    }
    else {
      this.renderer.setStyle(childElement, 'background-color', 'white');
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.setTimeINterval);
    this.resizeObserver.disconnect();
  }

  clearSelection(): void {
    this.selectedAssets = [];
    this.loadData();
  }

  clearLocationsSelection(): void {
    this.selectedLocations = [];
    this.loadData();
  }
}