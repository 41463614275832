import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseService } from 'app/@core/services/base.service';
import { StorageService } from "app/@core/services/storage.service";
import { UtilitiesService } from 'app/@core/services/utilities.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { pipe } from 'rxjs';
import { finalize, mergeMap, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ColumnFilterComponent } from '../column-filter/column-filter.component';
@Component({
  selector: 'column-manager',
  styleUrls: ['./column-manager.component.scss'],
  templateUrl: './column-manager.component.html',
})
export class ColumnManagerterComponent implements OnInit {
  @Output() changed = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() closeModalEvent = new EventEmitter();
  @Output() closeModalEventButton = new EventEmitter();
  @Output() applyViewColumn = new EventEmitter();

  @Input() menuColumns;
  @Input() tableSettingKey = '';
  isChangedAnyThing = false;
  columnObj: any = {
    userId: localStorage.getItem('companyUserId'),
    json: [],
    type: 'Custom',
    page: '',
    isDefault: true,
    presetName: "string",
    filterTableId: 0,
    isView:false,
    companyId: this.storageService.getCompanyID(),
    tableId:0
  }
  columnObjView: any = {
    userId: localStorage.getItem('companyUserId'),
    json: [],
    type: 'Custom',
    page: '',
    isDefault: true,
    presetName: "string",
    filterTableId: 0,
    companyId: this.storageService.getCompanyID()
  }
  isAllSelected = false;
  presetNameArray:any=[];
  defaultPresetsName:any;
  isDefaultset:any;
  presetName:any;
  filterTableID:any
  presetNameArrayDefault:any=[];
  assetData:any;
  formBool = false;
  addAssetModal?: BsModalRef;
  isEditBool:boolean=false;
  formData:any;
  editpresettableFilterId:any;
  editPresetTableFilterIDGet:any=null
  @ViewChild(ColumnFilterComponent) columnFilterComponent: ColumnFilterComponent;

  constructor(
    public requestService: BaseService,
    public storageService: StorageService,
    public utilityService: UtilitiesService,
    private modalService: BsModalService,
    private toaster: ToastrService,


  ) {
  }
  ngOnInit(): void {
     
    let presentName=localStorage.getItem("presetName")
    let presetNameDefault=localStorage.getItem("presetNameDefault")
    this.presetNameArray=JSON.parse(presentName)
    this.presetNameArrayDefault=JSON.parse(presetNameDefault)
    if(this.presetNameArray.length >0){
        this.presetNameArray.map(x=>{
          if(x.isDefault===true){
             
           this.defaultPresetsName= x.presetName

          }
        })
    }
    if(this.presetNameArrayDefault.length >0){
      this.presetNameArrayDefault.map(x=>{
        if(x.isDefault===true){
           
         this.defaultPresetsName= x.presetName

        }
      })
    }
     
    this.menuColumns = JSON?.parse(this.menuColumns);
    this.isAllSelected = this.isAllselectSlected();
  }

  toggleColumn(columnItem) {
    this.isChangedAnyThing = true;
    columnItem['isShowColumn'] = !columnItem['isShowColumn'];
    this.isAllSelected = this.isAllselectSlected();
  }

  isAllselectSlected() {
    return this.menuColumns.every(item => item.isShowColumn)
  }


  selectAll(event) {
    this.isAllSelected = event.target.checked;
    this.isChangedAnyThing = true;
    this.menuColumns.map(item => {
      item.isShowColumn = event.target.checked;
      return item;
    })
  }
  presetNameModal(presetModalTemplate) {
     
    if(this.formBool===true){
      this.openModal(presetModalTemplate);
    // this.formBool = true;
    this.requestService.showLoader();
    // this.requestService.getRequest(`companydivision/companydivision?ComDivisionId=${Obj.id}`).subscribe(
    this.requestService.getRequest(`tablefilter/editpreset?TableId=${this.editpresettableFilterId}`).subscribe(
    (response) => {
        this.formData = response;
        this.requestService.hideLoader();
      }, (error) => {
        this.toaster.error(`Something went wrong`, 'Opps!');
        console.log(error);
        this.requestService.hideLoader();
      });
    }
    else{

      this.openModal(presetModalTemplate);
    }
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "Are you sure you don't want to select this preset?",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   cancelButtonText: 'No'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //   // Uncomment if you want to apply columns immediately
    //     this.applyColumn();
    //   } else {
         
    //     this.closeModal();

    //     // Optional: You can add additional code here if needed when they click 'Cancel'
    //   }
    // });
  }
  // presetNameModal(presetModalTemplate){

  //   this.openModal(presetModalTemplate)
  //   // return
  //   // this.applyColumn();
  // } 
//   applyViewOnly(){
//      
//     this.isChangedAnyThing = false; // Reset after changes are applied
//      
//     // this.closeModal();
//     // this.menuColumns
//     // this.reload.emit(this.menuColumns);
//     this.columnObjView.page = this.tableSettingKey;
//     this.menuColumns.map(it => delete it.cellTemplate);
//     this.columnObjView.json = this.menuColumns;
//     this.columnObjView.isDefault=this.isDefaultset;
//     this.columnObjView.filterTableId=this.filterTableID;
//     this.columnObjView.presetName=this.presetName;
//     localStorage.setItem('applyColumnView',JSON.stringify(this.columnObjView));
//     this.applyViewColumn.emit()
//      
// }
  applyColumn(val) {
      const tableSettingKey=localStorage.getItem('tableSettingKey');
      let filteredData: any = localStorage.getItem(tableSettingKey);

      // var JsonFilterData= JSON.parse(filteredData)
      // localStorage.setItem(tableSettingKey+'1',JSON.stringify(JsonFilterData));      // localStorage.getItem(pageReference + "-filterJSON", JSON.stringify(parseData));

    localStorage.setItem("isViewBool",JSON.stringify(val));
    localStorage.setItem("jsonColumnData",JSON.stringify(this.menuColumns));
    localStorage.setItem("editPresetTableFilterIDGet", JSON.stringify(this.editPresetTableFilterIDGet===undefined? 0:this.editPresetTableFilterIDGet));
     
    this.isChangedAnyThing = false; // Reset after changes are applied
    if(this.editPresetTableFilterIDGet===undefined || this.editPresetTableFilterIDGet===null){
      this.columnObj.tableId=0
    }
    else{
      this.columnObj.tableId=this.editPresetTableFilterIDGet
    }
    this.closeModal();
    this.reload.emit(this.menuColumns);
    this.columnObj.page = this.tableSettingKey;
    this.menuColumns.map(it => delete it.cellTemplate);
    this.columnObj.json = this.menuColumns;
    this.columnObj.isDefault=this.isDefaultset;
    this.columnObj.filterTableId=this.filterTableID;
    this.columnObj.presetName=this.presetName;
    this.columnObj.isView=val;
    this.columnObj.tableId = this.editPresetTableFilterIDGet
     
    this.saveColumnsData(this.columnObj);
     // Call ColumnFilterComponent's initializeColumnFilter method
    // if (this.columnFilterComponent) {
      this.columnFilterComponent.initializeColumnFilter();
    // }
  }
  openModal(addAsset: TemplateRef<any>) {
     
    this.assetData = null;
    // this.formBool = true;
    this.utilityService.modalConfig.class = "modal-md modal-dialog-centered";
    this.addAssetModal = this.modalService.show(
      addAsset,
      this.utilityService.modalConfig
    );
  }
  closeModalPreset(eventValue) {
    if (eventValue === 'refresh-record') {
      // this.matTable.loadData();
    }
    this.addAssetModal.hide();
    // this.zone = 1;
  }
  presetNameForm(formValue){
      
     this.editPresetTableFilterIDGet=formValue.editPresetId
    let obj=
      {
        presetName: formValue.saveObj.presetName,
        companyId: this.storageService.getCompanyID(),
        userId: localStorage.getItem('companyUserId'),
        page: this.tableSettingKey,
        tableId:this.editPresetTableFilterIDGet===undefined ? 0 : this.editPresetTableFilterIDGet
      }
    this.requestService.checkPresetName(obj)
      .subscribe((response) => {
          
        if (response=== true || formValue.saveObj.presetName.toLowerCase() ==='system preset'){
          this.toaster.error(`Preset Name Already Exist`, 'Opps!');


          return
        }
        if(response=== false){
          this.toaster.success('Preset saved Successfully ', 'Success');

          let responsepreset:any = localStorage.getItem("responsePresetName");
          if(responsepreset){
          let parseData = JSON.parse(responsepreset);
          parseData=formValue.saveObj.presetName
          localStorage.setItem("responsePresetName", JSON.stringify(parseData));
        }
          this.presetName=formValue.saveObj.presetName;
      
         
          this.addAssetModal.hide();
          this.applyColumn(false);
        }
          
      },
       );
      
  //   let responsepreset:any = localStorage.getItem("responsePresetName");
  //   if(responsepreset){
  //   let parseData = JSON.parse(responsepreset);
  //   parseData=formValue.presetName
  //   localStorage.setItem("responsePresetName", JSON.stringify(parseData));
  // }
  //   this.presetName=formValue.presetName;

  //  
  //   this.addAssetModal.hide();
  //   this.applyColumn();
  }

  isRestoring: boolean = false;
  
  
  resetDefault() {
    this.isRestoring = true;
    this.requestService.getTableSettings(this.tableSettingKey, false).pipe(
      finalize(() => {
        this.isRestoring = false;
      })
    ).subscribe(response => {
      this.menuColumns = JSON.parse(response.defaultJson);
    });
    this.isAllSelected = this.isAllselectSlected();
    this.requestService.getTableSettings(this.tableSettingKey, true).pipe(
      finalize(() => {
        this.isRestoring = false;
      })
    ).subscribe((response) => {
      this.menuColumns = JSON.parse(response.defaultJson);
      this.isAllSelected = this.isAllselectSlected();
    })
  }


  drop(event: CdkDragDrop<string[]>) {
    this.isChangedAnyThing = true;
    moveItemInArray(this.menuColumns, event.previousIndex, event.currentIndex);
  }

  saveColumnsData(columnObj) {
     
    this.requestService.postTableSettings(this.tableSettingKey, columnObj)
      .subscribe(() => {
        this.closeModal();
        this.requestService.reloadCoulmn.next({ 'hard-reload': true });
      }, (error) => {
        console.log(error);
      });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }
  closeModalButton() {
    this.closeModalEventButton.emit();
  }
  // restore(){
  //   var presetName="system preset"
  // }
  restore(event:Event) {
    // Your existing logic to restore default settings
    // this.presetNameArray.forEach(preset => {
    //   if (!preset.isDefault) {
    //     this.onCheckboxChange(preset.presetName, preset.isDefault, preset.tableFilterId);
    //   }
    // });
  
    // this.presetNameArrayDefault.forEach(preset => {
      // if (!preset.isDefault) {
      var presetName=undefined;
      var isDefault=undefined;
      var tableFilterId=undefined
        this.onCheckboxChange(event,presetName, isDefault, tableFilterId);
      // }
    // });
  
    // Any other logic that needs to be part of restore
  }
  
 
  onCheckboxChange(event:Event, presetName:any, isDefault:any, tableFilterId:any) {
    this.requestService.showLoader();
    // this.editPresetTableFilterIDGet=tableFilterId
    this.editPresetTableFilterIDGet=null
  //   let filteredData: any = localStorage.getItem(this.tableSettingKey + "-filterJSON");
  //   if(filteredData && filteredData!=="null"){
  //   let parseData = JSON.parse(filteredData);
  //   parseData.presetName=presetName===undefined?null:presetName;
  //   localStorage.setItem(this.tableSettingKey + "-filterJSON", JSON.stringify(parseData));
  // }
  let responsepreset:any = localStorage.getItem("responsePresetName");
  if(responsepreset ){
    let parseData = JSON.parse(responsepreset);
    parseData=presetName === undefined ? "system preset" : presetName;
    localStorage.setItem("responsePresetName", JSON.stringify(parseData));
  }
    this.presetName=presetName===undefined ? "system preset" : presetName;
    isDefault = !this.isDefaultset
    this.filterTableID=tableFilterId
    let enablepresetObj ={
      tableFilterId: this.filterTableID,
      userId: localStorage.getItem('companyUserId'),
      companyId: this.storageService.getCompanyID(),
      page: this.tableSettingKey,
      presetName: this.presetName,
      isDefault: isDefault

    }
    this.requestService.enablePreset(enablepresetObj)
      .subscribe(() => {
         
        this.closeModal();
        this.applyColumn(false)
        // this.requestService.reloadCoulmn.next({ 'hard-reload': true });
        // this.reload.emit(this.menuColumns);
        // this.reload.emit(this.menuColumns);
      }, (error) => {
        console.log(error);
      });
      // this.closeModal();
      // this.applyColumn()
      // this.reload.emit(this.menuColumns);
      // this.requestService.reloadCoulmn.next({ 'hard-reload': true });
    
      let presentName=localStorage.getItem("presetName")
      let presetNameDefault=localStorage.getItem("presetNameDefault")
      this.presetNameArray=JSON.parse(presentName)
      this.presetNameArrayDefault=JSON.parse(presetNameDefault)
      if(this.presetNameArray.length >0){
          this.presetNameArray.map(x=>{
            if(x.isDefault===true){
               
             this.defaultPresetsName= x.presetName
  
            }
          })
      }
      if(this.presetNameArrayDefault.length >0){
        this.presetNameArrayDefault.map(x=>{
          if(x.isDefault===true){
             
           this.defaultPresetsName= x.presetName
  
          }
        })
      }
       
      this.menuColumns = JSON?.parse(this.menuColumns);
      this.isAllSelected = this.isAllselectSlected();
  }
    deletePreset(val){
       
      var tableFilterId = val
      
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to delete this preset?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
           
        // Uncomment if you want to apply columns immediately
          // this.applyColumn();
          this.requestService.removeRequest(`tablefilter/deletepreset?TableId=${tableFilterId}`).subscribe(
            res => {
              this.toaster.success('Preset deleted Successfully ', 'Success');
              this.editPresetTableFilterIDGet=val
              this.applyColumn(false);
              
            },
    
            err=> console.log(err.message)
          )
        } else {
           
          // this.closeModal();
  
          // Optional: You can add additional code here if needed when they click 'Cancel'
        }
      });
      this.editPresetTableFilterIDGet=val


      // this.requestService.removeRequest(`tablefilter/deletepreset?TableId=${tableFilterId}`).subscribe(
      //   res => {
      //     this.toaster.success('Preset deleted Successfully ', 'Success');
      //     this.applyColumn(false);
          
      //   },

      //   err=> console.log(err.message)
      // )
    }
  editPreset(preset,presetModalTemplate){
     
    this.editpresettableFilterId=preset.tableFilterId
    this.formBool = !this.formBool
    // this.formBool = false
    // this.editBool.emit(this.isEditBool)




    // this.openModal(presetModalTemplate);
    // this.formBool = true;
    // this.requestService.showLoader();
    // // this.requestService.getRequest(`companydivision/companydivision?ComDivisionId=${Obj.id}`).subscribe(
    // this.requestService.getRequest(`companydivision/companydivision?ComDivisionId=${preset}`).subscribe(
    // (response) => {
    //     this.formData = response;
    //     this.requestService.hideLoader();
    //   }, (error) => {
    //     this.toaster.error(`Something went wrong`, 'Opps!');
    //     console.log(error);
    //     this.requestService.hideLoader();
    //   });
  }
  

}
